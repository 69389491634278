<script setup lang="ts">
withDefaults(
  defineProps<{
    /**
     * Dark mode
     */
    darkMode?: boolean | 'auto'
    /**
     * Color scheme
     */
    colorScheme: OzDividerColorScheme
    /**
     * Orientation
     */
    orientation?: OzDividerOrientation
    /**
     * Thickness of divider in px
     */
    thickness?: 0.5 | 8 | 12
  }>(),
  {
    darkMode: 'auto',
    orientation: OzDividerOrientation.Horizontal,
    thickness: 0.5,
  },
)
</script>

<script lang="ts">
export enum OzDividerColorScheme {
  Solid = 'Solid',
  Translucent = 'Translucent',
  Popover = 'Popover',
  Selection = 'Selection',
}

export enum OzDividerOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export default {}
</script>

<template>
  <div
    :class="[
      // SHAPE
      orientation === OzDividerOrientation.Horizontal && {
        'h-[0.5px]': thickness === 0.5,
        'h-2': thickness === 8,
        'h-3': thickness === 12,
      },
      orientation === OzDividerOrientation.Vertical && {
        'w-[0.5px]': thickness === 0.5,
        'w-2': thickness === 8,
        'w-3': thickness === 12,
      },

      // COLOR
      colorScheme === OzDividerColorScheme.Solid && [
        {
          'bg-divider-gray dark:bg-divider-gray-dark': darkMode === 'auto',
          'bg-divider-gray': darkMode === false,
          'bg-divider-gray-dark': darkMode === true,
        },
      ],
      colorScheme === OzDividerColorScheme.Translucent && [
        'bg-opacity-30',
        {
          'bg-grey-200 dark:bg-white-400': darkMode === 'auto',
          'bg-grey-200': darkMode === false,
          'bg-white-400': darkMode === true,
        },
      ],
      colorScheme === OzDividerColorScheme.Popover && [
        {
          'bg-divider-popover dark:bg-divider-popover-dark': darkMode === 'auto',
          'bg-divider-popover': darkMode === false,
          'bg-divider-popover-dark': darkMode === true,
        },
      ],
      colorScheme === OzDividerColorScheme.Selection && [
        {
          'bg-grape-500 dark:bg-canary-500': darkMode === 'auto',
          'bg-grape-500': darkMode === false,
          'bg-canary-500': darkMode === true,
        },
      ],
    ]"
  />
</template>
